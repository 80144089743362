.header-btn {
align-items: center;
background-image: linear-gradient(#fff 0%,#ddd 100%);
border-radius: 22px;
box-sizing: border-box;
color: #32374d;
cursor: pointer;
display: inline-flex;
font-family: EuclidCircular;
font-size: 16px;
height: 35px;
justify-content: center;
line-height: 22.4px;
padding: 0 20px;
text-align: left;
text-decoration: none;
transition: all .27s ease-in-out;
white-space: nowrap;

&:hover {
  box-shadow: rgba(16,213,166,.45) 0 0 15px;
  transform: scale(1.05);
}

&:not([href]):hover {
  color: #212529;
}

}
