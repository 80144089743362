$ticket-button-active-bgcolor-default: #1e2424; // dark blue
$top-of-book-active-box-shadow-color: #68c4bc;
$ticket-button-active-shadow-default: #68c4bc;

.user-jumbotron {
    text-align: start;
    background: linear-gradient(166deg, black 39%, #027c7b 163%, #028786 100%);
    border: 1px solid $top-of-book-active-box-shadow-color;
    box-shadow: inset 0 0 1rem $top-of-book-active-box-shadow-color;
}

.datagrid-container {
    margin-top: 2rem;
    border: 1px solid $top-of-book-active-box-shadow-color;
    box-shadow: inset 0 0 1rem $top-of-book-active-box-shadow-color;
}

.dx-datagrid {
    background: #2f3640;
    color: white;
    // border: 2px solid $top-of-book-active-box-shadow-color;
}
.dx-datagrid-content {
    color: white;
}

.discord-avatar {
    height: 5rem;
    width: 5rem;
}

.user-greeting {
    font-size: 2.5rem;
    margin-bottom: 0;
}



@media (min-width: 414px) { 
    .user-greeting  {
        font-size: 1rem;
    }
 }
 
 .bind-button {
    height: 2.5rem;
    border: none;
    outline: none;
    color: #fff;
    background: linear-gradient(166deg, black 39%, #027c7b 163%, #028786 100%);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
 }
 
 
 @media (min-width: 576px) { 
    .user-greeting {
        font-size: 1.5rem;
    }
 }
 
 // Medium devices (tablets, 768px and up)
 
 @media (min-width: 768px) { 
    .user-greeting {
        font-size: 2rem;
    }
 }
 
 // Large devices (desktops, 992px and up)
 @media (min-width: 992px) { 
    .user-greeting {
        font-size: 2.5rem;
    }
 }
 
 // Extra large devices (large desktops, 1200px and up)
 @media (min-width: 1200px) { 
   
 }
 
 @media (min-width: 1920px) { 
    
 }
 