.generate-button {
    height: 3rem;
    width: 6rem;
    border: none;
    outline: none;
    color: #fff;
    background: linear-gradient(166deg, black 39%, #027c7b 163%, #028786 100%);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    box-shadow: inset 0 0 1rem #68c4bc;
  }