@import "../../../assets/css/colors.scss";
@import "../../../assets/css/main.scss";

.something {
  // color:red;
  display:flex;
  justify-content: space-between;
}

.pxDetails {
  // color:orange;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.renewBtn {
  min-width: 20%;
}

.rnleft{
  // justify-self:flex-start;
}