@import '../../assets/css/colors.scss';
#footer{
    margin-top: 2rem;
    .jumbotron {
        padding: 1rem ;
    }
}
.char {
    color:$char;
}
.cp {
    cursor:pointer;
}