@import "./assets/css/known.scss";
@import "./assets/css/colors.scss";
@import "./assets/css/main.scss";
@import "./assets//css/animate.css";

.App {
  min-height: 100vh;
  height: 100vh;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


$inconUrl: "https://fonts.googleapis.com/css?family=Inconsolata:normal,bold,black,medium,Semi-bold&display=fallback";


@import url($inconUrl);

// /*
@font-face {
  font-family: 'Inconsolata-Black';
  src: local('Inconsolata-Black'),
     url(./assets/fonts/Inconsolata-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolatas-Bold';
  src: local('Inconsolata-Bold'),
     url(./assets/fonts/Inconsolata-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inconsolata-Medium';
  src: local('Inconsolata-Medium'),
     url(./assets/fonts/Inconsolata-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Inconsolata-SemiBold';
  src: local('Inconsolata-SemiBold'),
     url(./assets/fonts/Inconsolata-SemiBold.ttf) format('truetype');
}
 

.black-hat-header {
  font-family: 'Inconsolata-Black';
  // font-weight: 900;
  font-size: 3rem;
}

@media (max-width: 640px) {  // sm
  .black-hat-header{
    font-size: 2rem;
  }

}

.black-hat-header-mini {
  font-family: 'Inconsolata';
  font-weight: 900;
  font-size: 2rem;
}

.black-hat-paragraph {
  font-family: 'Inconsolata';
  font-weight: bold;
}


.error-text {
  color: #cc0000;
}


.App-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.abcp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.black-hat-splash {

}

.no-cursor {
  transition: none;
}

.navbar, .navbar-brand {
  color: white !important;
  background-color: transparent;
}

.App-link {
  color: #61dafb;
}

$ticket-button-active-bgcolor-default: #1e2424; // dark blue
$top-of-book-active-box-shadow-color: #68c4bc;
$ticket-button-active-shadow-default: #68c4bc;
$bg-color: #1e2424;

body {
  //  background-color: #000 ;
  font-family: "Inconsolata", "Avenir", "sans-serif";
  font-weight: 500;
  //  font-family: 'Inconsolata-Medium', 'Inconsolata-Black', 'Inconsolata-Bold', 'Inconsolata-SemiBold';
   color: #fff;
   background-color: #222;
   margin: 0;
   padding: 0;
}

.splash-background {
    background: rgb(0,0,0);
    background: linear-gradient(166deg, black 39%, #027c7b 163%, #028786 100%);
    border: 1px solid $top-of-book-active-box-shadow-color;
    box-shadow: inset 0 0 1rem $top-of-book-active-box-shadow-color;
    overflow: auto;
}

.modal-content {
  background: rgb(0,0,0);
  background: linear-gradient(166deg, black 39%, #027c7b 163%, #028786 100%);
  border: 1px solid $top-of-book-active-box-shadow-color;
  box-shadow: inset 0 0 1rem $top-of-book-active-box-shadow-color;
}

.splash-loader {
    font-family: 'Inconsolata-Black';
    font-size: 2rem;
}

@keyframes anim-glow {
	0% {
		box-shadow: 0 0 rgba(#68c4bc, 1);
	}
	100% {
		box-shadow: 0 0 10px 8px transparent;
		border-width: 2px;
	}
}

.icon	{
	// border-radius: 50%;
	border: 2px solid #68c4bc;
	text-align: center;
	position: relative;
	animation: anim-glow 2s ease infinite;
}

.jumbotron {
  background-color: transparent;
  margin: 0;
  padding: 1rem;
}

.card {
  font-family: 'Inconsolata-Medium';
  // font-weight: 500;
  color: black;
}

.login-button {
  font-weight: 500;
  height: 3rem;
  border: none;
  outline: none;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}


textarea .form-control{
  border:none;
}
.form-control {
  border:none;
  background: $theme_input;
  color:$theme_input_text;
  // color:white;
  // background: white;
  // color: black;

}

.form-control:focus {
  color:$theme_input_text;
  // color: black;
  background-color:$theme_input;
  // background-color: white;
}
::placeholder {
  color:black;
  // color: white !important;
}


.purchase-card {
  border: 1px solid $top-of-book-active-box-shadow-color;
  box-shadow: inset 0 0 1rem $top-of-book-active-box-shadow-color;
  width: 25rem;
  border-radius: 1rem;
}
.purchase-list-group {
  border: 1px solid $top-of-book-active-box-shadow-color;
  border-top: none;
  border-bottom: none;
  box-shadow: inset 0 0 1rem $top-of-book-active-box-shadow-color;
}

.purchase-button {
  height: 3rem;
  border: none;
  outline: none;
  color: #fff;
  background: linear-gradient(166deg, black 39%, #027c7b 163%, #028786 100%);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.purchase-button:focus {
  border: none;
  box-shadow: none;
}

.login-button:hover {
  background-color: transparent;
}
.login-button:active {
  background-color: transparent;
}
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-primary:focus{
  background-color: transparent;
  box-shadow: none;
}

.splash-row{
  position: relative;
  // bottom: 5.5rem;
}

.glow-on-hover-button {
  height: 3rem;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover-button:before {
  content: '';
  background: linear-gradient(45deg, #00ffd5, #002bff, #7a00ff, );
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover-button:active {
  color: #000
}

.glow-on-hover-button:active:after {
  background: transparent;
}

.glow-on-hover-button:hover:before {
  opacity: 1;
}

.glow-on-hover-button:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

a {
  text-decoration: none;
}
a:active{
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}


