$char: #36454f;
$ebony:#555d50;
$blue900: #2a4365;
// $char:$ebony;

// $char:#3b444b;
$gradient:linear-gradient(166deg,#000 39%,#027c7b 163%,#028786 0);
$gradient_hex1: #027c7b;
$gradient_hex2: #028786;
$white: rgb(255, 255, 255);
$tblue: rgba(34,39,55,.97);
$shopgrad:linear-gradient(to right top, #34253b, #382049, #391b58, #361568, #2c107a);
 $darkpup: #590a84;
 $lightish_purp: #6e06a5;
$ucontent_color: #212529;

/* theming */

// $theme_nav: theme('colors.blue.500');
$theme_nav: $gradient;
// 
$theme_nav: theme('t.colors.blue.500');


$theme_input: $char;
$theme_input_text: white;