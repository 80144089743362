@import '../../assets/css/colors.scss';

.primary {
    background:white;
}
.shopbtn {
  background: $char;
  transition: all .27s ease-in-out;
  &:hover {
   
  // box-shadow: rgba(16,213,166,.45) 0 0 15px;
  transform: scale(1.05);
}

&:not([href]) {
  // color: $ucontent_color;
  // color: #212529;
}

&:not([href]):hover {
  // color:$ucontent_color;
  // color: #212529 (this hex is same as var above)
}
}

.second {
  background: $darkpup;
  // background: $lightish_purp;
  // background:red;
  // background: $char;
}
.cp {
  cursor: pointer;
}

.tw {
  color:$gradient_hex2;
}
.header-content {
  align-items: center;
box-sizing: border-box;
color: #212529;
display: flex;
font-family: EuclidCircu212529lar;
font-size: 12px;
line-height: 16.8px;
text-align: left;
}
.header-btn {
  align-items: center;
background-image: linear-gradient(#fff 0%,#ddd 100%);
border-radius: 22px;
box-sizing: border-box;
color: #32374d;
cursor: pointer;
display: inline-flex;
font-family: EuclidCircular;
font-size: 16px;
height: 35px;
justify-content: center;
line-height: 16.8px;
padding: 0 20px;
text-align: left;
text-decoration: none;
transition: all .27s ease-in-out;
white-space: nowrap;

&:hover {
  box-shadow: rgba(16,213,166,.45) 0 0 15px;
  transform: scale(1.05);
}

&:not([href]) {
  color: #212529;
}

&:not([href]):hover {
  color: #212529;
}
}

.page-inner {
align-items: center;
box-sizing: border-box;
/* color: #222; */
display: flex;
/* font-family: EuclidCircular; */
/* font-size: 12px; */
height: 75px;
justify-content: space-between;
line-height: 16.8px;
margin: 0 auto;
max-width: 1480px;
padding: 0 0px;
}

.page-header {
  // background: $theme_nav;
box-shadow: rgba(0,0,0,.1) 0 4px 10px;
box-sizing: border-box;
/* color: #222; */
/* font-family: EuclidCircular; */
/* font-size: 12px; */
height: 75px;
// left: 0;
line-height: 16.8px;
// position: absolute;
// top: 0;
width: 100%;
/* z-index: 1000; */
}