$coal: #36454f;
$grad: linear-gradient(166deg,#000 39%,#027c7b 163%,#028786 100%);


$vgrad:linear-gradient(0deg,#d3555b 0%,#f57077 100%);

// body {
//   background:$grad;
// }
$gray1:#9EACD6;
$grey2:#36454f;
$gray3:$grad;
$gray4:#616161;
$color5:#424242;
$blackmail:#1D0561;
$gradient1: linear-gradient(180deg, #FFFFFF 0%, #6284FF 50%, #FF0000 100%);
$gradient2:linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);

$gradienteps: linear-gradient(to bottom right, #34253B, #2D1882);
$purplegrey: linear-gradient(to bottom right, #36454F, #34253B);

$fuck:linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(52,37,59,1) 100%);

$v3: linear-gradient(to right top, #34253b, #462361, #531e8a, #5617b9, #4b12eb);
$v4:linear-gradient(to top, #34253b, #382049, #391b58, #361568, #2c107a);
$smth:$v4;
$v5:linear-gradient(to right top, #34253b, #382049, #391b58, #361568, #2c107a);
$smth:$v5;
.fuck {
    background: $smth;
}
.snd {
  border-width: 0;
  // background:red;
}
.hasBg{
  background:$smth;
}
.disableBg {

}
.switch {
  // background:$smth;
border-radius: 26px;
border-width: 0;
color:white;
// color: #ddd;
cursor: pointer;
// font-family: Montserrat,sans-serif;
// font-size: 16px;
font-weight: 700;
margin: 0;
outline: none;
padding: 12px 18px;
scroll-behavior: smooth;
text-align: center;
text-decoration: none;
// transition-duration: .4s;
// transition-duration: .2s;
user-select: none;
z-index: 5;

  &:active{
    outline:none;
  }
&:disabled {
  cursor: not-allowed;
 
}
}
button:focus {
  outline:none;
}
.grow {
  flex-grow:1;
}
.purchasecoke {
  // margin-top:5rem;
  margin-bottom:0.65rem;
  color:red;
  // flex-grow: 1;
  font-weight: bold;
  // margin-top:11
  
  height: 3rem;
  border: none;
  outline: none;
  color: #fff;
  background: linear-gradient(166deg, black 39%, #027c7b 163%, #028786 100%);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}
.gey {
 // align-items: center;
  background:$smth;
// background-image: linear-gradient(0deg,#d3555b 0%,#f57077 100%);
border-radius: 18px;
border-radius: 18px;
border-width: 0;
box-shadow: rgba(0,0,0,.6) 0 0 25px;
color: white;
// display: flex;
// flex-direction: column;
// font-family: Montserrat,sans-serif;
min-height:500px;
min-width:400px;
// height: 500px;
// justify-content: space-around;
// margin: 40px 20px;
// margin-left:1rem;
// margin-right:1rem;
outline: none;
outline: 0;
padding: 2vh 2vw;
// padding-
scroll-behavior: smooth;
text-align: center;
text-decoration: none;
text-decoration: none;
user-select: none;
width: calc(20%);
z-index: 5;
}
.ffont-size {
  // font-size:16px;
}
@media (min-width: 640px) {  // sm
  .gey {
    min-width: 400px;
  }

}
@media (min-width: 768px) { //md
  .gey {
    min-width: 500px;
    min-height:600px;
  }
 
}
@media (min-width: 1024px) { //lg
 
}
@media (min-width: 1280px)  { // xl
  .gey {
  // min-width:550px;
  }

}

.form-control {
  background: #36454f;
  border:none;
  color:white;
  
}