a, a:hover {
    color: inherit;
}
a:active {
    border-bottom: 1px solid #fff;
}

.navbar-link {
    font-family: 'Inconsolata';
    font-weight: 500;
    font-size: 1rem;
}
.gg {
    // color: red;
}
.FUCK {
    // background:red !important;
}