@import "./colors.scss";
h1 {
    font-size: 2.5rem !important;
}
h2 {
    font-size: 2rem !important;
}
h3 {
    font-size: 1.75rem !important;
}
h4 {
    font-size: 1.5rem !important;
}
h5 {
    font-size: 1.25rem !important;
}


h6 {
    font-size: 1.25rem;
}


.list-area {
// background: $char;
outline:none;
border: none;
&:focus{
    outline: none;
//   background:$char;
}
}

button:focus {
  outline:none;
}
::-webkit-scrollbar {
  cursor: pointer;
  width: 6px;
}

::-webkit-scrollbar-track {
  background: 0 0;
}

::-webkit-scrollbar-thumb {
  background:$shopgrad;
  // background:#38b2ac;
  // background: #4D525B;
  /* background: var(--sexygrey); */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background:$shopgrad;
  // background:#38b2ac;
  // background: #483A6F;
  cursor: pointer;
}
