@import '../../assets/css/colors.scss';
$grad: linear-gradient(166deg,#000 39%,#027c7b 163%,#028786 100%);
$pgrad:linear-gradient(to right top, #34253b, #382049, #391b58, #361568, #2c107a);

// body {
// margin:30px 30px;
//   // background: $grad;
// }
.feature-box {
//   color:white;
  // padding:
  background:$pgrad;
  --bg-opacity: 0.99;
//   background:$pgrad;
//   min-height:200px;
//   min-width:200px;
}


